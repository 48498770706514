import { BaseUrls } from "../types";

const baseUrls: BaseUrls | any = {
  local: "http://localhost:8080/api/v1",
  dev: "https://dev.familyprohealth.life/api/v1",
  qa: "https://qa.familyprohealth.life/api/v1",
  load: "https://load.familyprohealth.life/api/v1",
  prod: "https://portal.familyprohealth.life/api/v1",
  uat: "https://preprod.familyprohealth.life/api/v1",
  maintenance: "https://maintenance.familyprohealth.life"
};

export const ENV: string = process.env.REACT_APP_RUNNING_ENV
  ? process.env.REACT_APP_RUNNING_ENV
  : "dev";

export const baseURL = baseUrls[ENV];

export enum apiRoutes {
  LOGIN = "/webLogin",
  LOGOUT = "/logout",
  CHANGE_PASSWORD = "/register/changePassword",
  FORGOT_PASSWORD = "/forgetpassword",
  GETPRIVACYPOLICY = "/getPolicy",
  REGISTER_USER = "/register",
  GETALLUSERS = "/users",
  USER = "/user",
  GETORDERSBYUSERS = "/order/user",
  GETORDERSUMMARY = "/order/summary",
  ADD_ORDER_ADDRESS = "/order/addresses",
  UPDATE_ORDER_ADDRESS = "/order/addresses",
  GET_ORDER_ADDRESS = "/order/addresses",
  GETALLSUBSCRIPTIONPLAN = "/subscriptionplans",
  CANCEL_SUBSCRIPTIONPLAN = "/suspendSubscription",
  CHECK_UPGRADE = "/userSubscription/checkUpgrade",
  PLAN = "/subscriptionplan",
  SELECTPLANS = "/order/select-plan",
  DEACTIVATEPLAN = "/subscriptionplan/deactivatePlan",
  ACTIVATEPLAN = "/subscriptionplan/activatePlan",
  SAVEACTIVATEPLAN = "/subscriptionplan/saveActivatePlan",
  UPDATEBENEFICIARY = "/user/beneficiary",
  ADDBENEFICIARY = "/order/beneficiary",
  TERMSOFUSE = "/getTermsOfUse",
  GETALLDEVICES = "/devices",
  GETDEVICEBYID = "/device",
  CREATEDEVICE = "/device",
  UPDATEDEVICE = "/device",
  EDITUPDATEDEVICE= "devicetype/subscriber",
  GETPRODUCT = "/products",
  GETALLORDER = "/orders",
  UPDATEORDER = "/order",
  UPDATEORDERCOUPON = "/order/applycoupon",
  ORDER_EXPORT = "/order/export",
  CREATE_PASSWORD = "/user/createNewPassword",
  CREATESUBSCRIPTION = "/order/checkout",
  UPDATEUSER = "/user/update",
  GETALLDEVICESBYUSERID = "/orderUserDevices",
  ORDERDEVICE = "/order/device",
  USERSUBSCRIPTIONPLAN = "/userSubscription",
  BILLINGHISTORY = "/user/transaction",
  DOWNLOADINVOICE = "/download/invoice",
  GETALLUSEROFSUBSCRIBER = "/user/beneficiaries",
  ROLES = "/roles",
  DEACTIVATEUSER = "/updateUserStatus",
  UPDATEFAMILYDATA = "user/family",
  IMPORTORDERS = "/order/import",
  GETSTRIPEDETAILS = "/userSubscription/stripe/details",
  GETUPGRADEPLANSBYID = "/getUpgradePlans",
  UPGRADEPLANSBYID = "/userSubscription/reviseSubscription",
  FORGOT_PASSCODE = "/user/forgotPasscode",
  RESET_PASSCODE = "/user/sendresetlink",
  SEND_MESSAGE = "/notifications/sendMessageNotification",
  ADD_NEW_BENEFICIARY = "/userSubscription/addNewBeneficiaries",
  DOWNLOAD_ORDER_INVOICE = "/download/orderInvoice",
  GETROLEPERMISSION = "/rolehaspermission",
  GETALLCOUPONS = "/campaigns",
  CREATENEWCOUPON = "/campaign",
  TENANTS = "/tenants",
  AUDIT = "/audits",
  AUDIT_EXPORT = "/audit/export",
  CUSTOMIZE_PLAN = "order/customize-plan",
  ACTIVATESUBSCRIPTIONBYID = "/activateSubscription",
  NOTIFICATION="/notifications/readAll",
  UPDATENOTIFICATION="/notifications/update",
  UPDATEORDERPLAN = "/order/updatePlan",
  GETPREFERREDCOMMUNICATION = "/user/usercommpreference",
  UPDATEPREFERREDCOMMUNICATION = "/user/usercommpreference",
  REFRESH_TOKEN="/refreshTokens",
  SUPPORTED_COUNTRIES ="supported-countries",
  ORDERDELETE = "order/delete",
  CUSTOMISEPLAN = "subscriptionplan/devices",
  STRIPE_CHECKOUT="/order/subscription-device",
  DEVICESTRIPE= "order/stripe/checkout",
  PAYMENTMETHOD = "paymentMethods",
  CHANGE_PAYMENT_METHOD = "customerPortal",
  UPDATE_BENEFICIARY="userSubscription/update/beneficiary/count",
}
