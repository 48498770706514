import {
  Box,
  Card,
  Divider,
  Typography,
  Grid,
  Dialog,
  IconButton,
  DialogTitle,
} from "@mui/material";
import { logo } from "../../../assets/images";
import "./style.css";
import Buttons from "../../common/button";
import { Input, PasswordInput } from "../../common/input";
import { useForm, Controller } from "react-hook-form";
import {
  setAuthInfo,
  setIpAddress,
  setLogin,
  setUserName,
} from "../../../redux/features/auth/authSlice";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { Page } from "../../../routes/config";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../../../redux/features/alert/alertSlice";
import { MessageAlert } from "../../common/alert/alert";
import {
  useGetIpQuery,
  useLoginMutation,
} from "../../../redux/services/authApiSlice";
import { useGetOrderMutation } from "../../../redux/services/orderApiSlice";
import { setOrder } from "../../../redux/features/order/orderSlice";
import { handleErrors, updateSteps } from "../../../utils";
import { JourneyStatus, OrderStatus, UserRoles } from "../../../constants/enum";
import { setBeneficiaryCount } from "../../../redux/features/user/beneficiarySlice";
import { PrivacyPolicy } from "./userRegistration/privacyPolicy";
import CloseIcon from "@mui/icons-material/Close";
import { RegExpressions } from "../../../constants/regExp";
import Loader from "../../common/loader/loader";
import Famhealth from "../../common/famHealth/Famhealth";

export const Login = () => {
  const activeStep = useSelector((state: any) => state?.userSteps?.activeSteps);
  const inputRef = useRef<HTMLInputElement | null>();
  const completedSteps = useSelector(
    (state: any) => state?.userSteps?.completedSteps
  );
  //API Integration
  const [userLogin, userLoginData] = useLoginMutation();
  const [orders, ordersData] = useGetOrderMutation();
  const [showPrivacyModal, setShowPrivacyModal] = useState(false);
  const [termOfUse, setTermsofUse] = useState(false);
  const { data, isSuccess } = useGetIpQuery({
    refetchOnMountOrArgChange: true,
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      userName: "",
      password: "",
    },
  });

  const onSubmit = (credentials: any) => {
    userLogin(credentials);
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef?.current.focus({ preventScroll: true });
    }
  }, []);

  useEffect(() => {
    if (userLoginData?.isSuccess) {
      const { accessToken, idToken, refreshToken, userInfo, tokenScope } =
        userLoginData?.data?.result;
      // setting auth state
      dispatch(
        setAuthInfo({
          userInfo,
          accessToken,
          idToken,
          refreshToken,
          tokenScope,
          userId: userInfo?.userId,
        })
      );
      dispatch(
        setUserName({
          fullName: userInfo?.fullName,
        })
      );
      if (
        userInfo?.hasAcceptedTnC &&
        userInfo?.roleName == UserRoles.SUBSCRIBER
      ) {
        orders(userInfo?.userId);
      } else {
        dispatch(setLogin(true));
        dispatch(
          setAlert({
            open: true,
            alertType: "success",
            message: "Login successfully",
          })
        );
        if (
          userInfo?.roleName == UserRoles.PLATFORM_ADMIN ||
          userInfo?.roleName == UserRoles.PLATFORM_SUPER_ADMIN
        ) {
          navigate(Page.MANAGEUSERS);
        } else if (userInfo?.roleName == UserRoles?.PLATFORM_SUPPORT) {
          navigate(Page.MANAGEUSERS);
        } else {
          navigate(Page.REGISTERATION);
        }
      }
    }
  }, [userLoginData?.isSuccess]);

  useEffect(() => {
    handleErrors(userLoginData, dispatch);
  }, [userLoginData?.isError]);

  useEffect(() => {
    if (ordersData?.isSuccess) {
      if (
        ordersData?.data?.result?.length &&
        ordersData?.data?.result?.length > 1
      ) {
        const {
          orderId,
          orderType,
          orderDate,
          journeyStatus,
          beneficiaryCount,
        } = ordersData?.data?.result[0];
        // setting auth state
        dispatch(
          setOrder({
            id: orderId,
            type: orderType,
            date: orderDate,
          })
        );
        updateSteps(JourneyStatus.COMPLETED, completedSteps, dispatch);
        dispatch(setBeneficiaryCount(beneficiaryCount));
        //setting isLoggedIn
        dispatch(setLogin(true));
        dispatch(
          setAlert({
            open: true,
            alertType: "success",
            message: "Login successfully",
          })
        );
        navigate(Page.MANAGEUSERS);
      } else if (ordersData?.data?.result?.length == 1) {
        const {
          orderId,
          orderType,
          orderDate,
          journeyStatus,
          beneficiaryCount,
          orderStatus,
          invoiceUrl,
        } = ordersData?.data?.result[0];
        // setting auth state
        dispatch(
          setOrder({
            id: orderId,
            type: orderType,
            date: orderDate,
            orderStatus: orderStatus,
            invoiceUrl: invoiceUrl,
          })
        );
        if (
          orderStatus == OrderStatus.CONFIRMED ||
          orderStatus == OrderStatus.INTRANSIT ||
          orderStatus == OrderStatus.DELIVERED ||
          orderStatus == OrderStatus.PARTIALLY_DELIVERED
        ) {
          updateSteps(JourneyStatus.COMPLETED, completedSteps, dispatch);
          dispatch(setBeneficiaryCount(beneficiaryCount)); //setting isLoggedIn
          dispatch(setLogin(true));
          dispatch(
            setAlert({
              open: true,
              alertType: "success",
              message: "Login successfully",
            })
          );
          navigate(Page.MANAGEUSERS);
        } else {
          updateSteps(journeyStatus, completedSteps, dispatch);
          dispatch(setBeneficiaryCount(beneficiaryCount)); //setting isLoggedIn
          dispatch(setLogin(true));
          dispatch(
            setAlert({
              open: true,
              alertType: "success",
              message: "Login successfully",
            })
          );
          navigate(Page.REGISTERATION);
        }
      } else if (ordersData?.data?.result?.length == 0) {
        updateSteps(JourneyStatus.PRIVACYPOLICY, completedSteps, dispatch);
        dispatch(setLogin(true));
        dispatch(
          setAlert({
            open: true,
            alertType: "success",
            message: "Login successfully",
          })
        );
        navigate(Page.REGISTERATION);
      }
    }
  }, [ordersData?.isSuccess]);

  useEffect(() => {
    handleErrors(ordersData, dispatch);
  }, [ordersData?.isError]);

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 200,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    // setOpen(true)
  };
  const handleClose = () => setOpen(false);

  const onForgotPasswordClick = () => {
    navigate(Page.FORGETPASSWORD);
  };

  const [height, setHeight] = useState(0);

  useEffect(() => {
    function updateSize() {
      setHeight(window.innerHeight);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  const onPrivacyPolicyClick = () => {
    setShowPrivacyModal(true);
  };
  const onModalClose = () => {
    setShowPrivacyModal(false);
    setTermsofUse(false);
  };
  const onTermsofuseClick = () => {
    setTermsofUse(true);
    setShowPrivacyModal(true);
  };
  useEffect(() => {
    if (isSuccess) {
      dispatch(setIpAddress({ ipAddress: data?.ipAddress }));
    }
  }, [isSuccess]);
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
      var x: any = document.getElementById("box-login");
      setTimeout(() => {
        x.scrollTo(0, 0);
      }, 50);
    }
  }, []);
  return (
    <>
      <Loader show={userLoginData?.isLoading || ordersData?.isLoading} />
      <Box height={`${height}px`} className="container-body" id="box-login">
        <Grid container justifyContent={"center"} height={"13vh"}>
          <Grid item xs={12}>
            <MessageAlert></MessageAlert>
          </Grid>
        </Grid>
        <Grid container justifyContent={"center"}>
          <Grid item xs={11} sm={11} md={9} lg={5.6} xl={4.4}>
            <Card className="container-card">
              <Grid container justifyContent={"center"}>
                <Grid item xs={10} xl={8.6}>
                  <Box className="logo">
                    <img src={logo} className="loginLogo"></img>
                  </Box>
                  <Typography className="card-title">
                    <span>Sign in to your <Famhealth /> account</span>
                  </Typography>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container>
                      <Grid item xs={12} className="grid-item">
                        <Controller
                          render={({
                            field: { ref, ...field },
                            fieldState: { invalid, error },
                          }) => (
                            <Input
                              label="Email or Mobile Number"
                              {...field}
                              value={field.value}
                              placeholder="Enter email or mobile number"
                              error={Boolean(error)}
                              helperText={error?.message ? error?.message : " "}
                              onChange={field.onChange}
                              inputRef={(e: any) => {
                                ref(e);
                                inputRef.current = e;
                              }}
                              required={true}
                              // autoFocus={true}
                            />
                          )}
                          name="userName"
                          control={control}
                          rules={{
                            required: "Please enter email or mobile number",
                            pattern: {
                              value: RegExpressions?.emailornumber,
                              message:
                                "Please enter valid email or mobile number ",
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} className="grid-item">
                        <span
                          className="left-side"
                          onClick={onForgotPasswordClick}
                        >
                          <span className="forgot-link">
                            <a href="">Forgot Password</a>
                          </span>
                        </span>
                        <Controller
                          render={({
                            field: { ref, ...field },
                            fieldState: { invalid, error },
                          }) => (
                            <PasswordInput
                              label="Password"
                              {...field}
                              value={field.value}
                              placeholder="Enter your password"
                              error={Boolean(error)}
                              helperText={error?.message ? error?.message : " "}
                              onChange={field.onChange}
                              inputRef={ref}
                              required={true}
                              info={true}
                            />
                          )}
                          name="password"
                          control={control}
                          rules={{
                            required: "Please enter the password",
                            pattern: {
                              value: RegExpressions?.Password,
                              message: "Please enter the valid password",
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={11.8} className="grid-item">
                        <Buttons
                          variant="contained"
                          type="primary"
                          buttonType="submit"
                          text="Sign In"
                        />
                      </Grid>
                      <Grid item xs={12} className="policy-text grid-item">
                        <span>
                          Click to view{" "}
                          <a
                            href="#"
                            className="addunderline"
                            onClick={onPrivacyPolicyClick}
                          >
                            Privacy Policy
                          </a>{" "}
                          and{" "}
                          <a
                            href="#"
                            className="addunderline"
                            onClick={onTermsofuseClick}
                          >
                            Terms of Use
                          </a>
                        </span>
                      </Grid>
                      <Grid item xs={12} sx={{ mt: 1 }} className="grid-item">
                        <Divider className="divider">
                          <span className="divider-text">
                            New to familyprohealth?
                          </span>
                        </Divider>
                      </Grid>
                      <Grid item xs={11.8} className="grid-item">
                        <Buttons
                          onClick={() => navigate(Page.CREATEACCOUNT)}
                          variant="contained"
                          type="secondary"
                          text="Create Your Account"
                        />
                      </Grid>
                    </Grid>
                  </form>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>

        <Grid container justifyContent={"center"} className="helpContact">
          <Grid item xs={11} sm={11} md={9} lg={5.6} xl={4.4}>
            <Box className="bottom-text">
              <Box>
                <span className="textbtm text-font">
                  <a
                    href="https://familyprohealth.com/faq"
                    className="addunderline"
                  >
                    Help
                  </a>{" "}
                  &nbsp;|&nbsp;{" "}
                  <a
                    href="https://familyprohealth.com/contact-us"
                    className="addunderline"
                  >
                    Contact us
                  </a>
                </span>
              </Box>
              <Box>
                <span className="textbtm lastText">
                  @{new Date().getFullYear()} familyprohealth Inc. All rights
                  reserved.
                </span>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Dialog open={showPrivacyModal} className="PrivacyPolicyModal">
          <DialogTitle className="privacyCardText">
            {termOfUse ? "Terms Of Use" : "Privacy Policy"}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={onModalClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <PrivacyPolicy
            modal={true}
            termofUseModal={termOfUse}
            setTermofUseModal={setTermsofUse}
          />
        </Dialog>
      </Box>
    </>
  );
};
